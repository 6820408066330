import React, { useState, useEffect } from "react";
import { Td, Tr } from "@lightspeed/cirrus-table";
import { Button } from "@lightspeed/flame/Button";
import { Input } from "@lightspeed/flame/Input";
import { useDispatch, useSelector } from "react-redux";
import * as databaseActions from "../../../redux/actions/databaseActions";

const Row = ({ item, retail }) => {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const userState = useSelector((state) => state.userState);
  const database = useSelector((state) => state.database);
  const [edit, setEdit] = useState(false);
  const [changed, setChanged] = useState(false);
  const [inputBox1, setInputBox1] = useState("");
  const [inputBox2, setInputBox2] = useState("");
  const normalAccount = item.account_id === null ? "" : item.account_id;
  const normalAccountDescription =
    item.account_description === null ? "" : item.account_description;
  const normalCheck = item.flash === "Yes" ? true : false;
  const normalCogs = item.cogs === null ? "" : item.cogs;
  const normalCogsDescription =
    item.cogs_description === null ? "" : item.cogs_description;

  useEffect(() => {
    setInputBox1(normalAccount);
    setInputBox2(normalAccountDescription);
    if (normalAccount === "" && normalAccountDescription === "") {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [normalAccount, normalAccountDescription]);
  useEffect(() => {
    if (normalAccount === inputBox1 && normalAccountDescription === inputBox2) {
      setChanged(false);
    } else {
      setChanged(true);
    }
  }, [inputBox1, inputBox2, normalAccount, normalAccountDescription]);

  /*
  PROMJENA
  */

  const handleUpdate = () => {
    if (database.currentCompany.type === "RETAIL") {
      console.log("TU SAM 14")
      dispatch(
        databaseActions.updateRetailCategory(
          userState.user.user_id,
          item.company_id,
          database.currentShop.shop_id,
          inputBox1,
          inputBox2,
          item.category_id,
          normalCheck === true ? "Yes" : "",
          normalCogs,
          normalCogsDescription,
          databaseActions.getShopCategories(
            database.currentCompany.id,
            userState.user.user_id,
            database.currentShop.shop_id
          )
        )
      );
    } ;


    if (database.currentCompany.type === "RETAILX") {
      console.log("TU SAM 14X")
      dispatch(
        databaseActions.updateRetailCategoryX(
          userState.user.user_id,
          item.company_id,
          database.currentShop.shop_id,
          inputBox1,
          inputBox2,
          item.category_id,
          normalCheck === true ? "Yes" : "",
          normalCogs,
          normalCogsDescription,
          databaseActions.getShopCategoriesX(
            database.currentCompany.id,
            userState.user.user_id,
            database.currentShop.shop_id
          )
        )
      );
    };

    if (database.currentCompany.type === "RESTOK") {
      if (database.currentCompany.direct) {
        dispatch(
          databaseActions.updateRestoCategoriesK(
            userState.user.user_id,
            item.company_id,
            item.category_id,
            inputBox1,
            inputBox2,
            0,
            databaseActions.getAreaCategoriesK(
              userState.user.user_id,
              database.currentCompany.id,
              0
            )
          )
        );
      } else {
        dispatch(
          databaseActions.updateRestoCategoriesK(
            userState.user.user_id,
            item.company_id,
            item.category_id,
            inputBox1,
            inputBox2,
            database.currentShop.area_id,
            databaseActions.getAreaCategoriesK(
              userState.user.user_id,
              database.currentCompany.id,
              database.currentShop.area_id
            )
          )
        );
      }
    } else {
      if (database.currentCompany.direct) {
        dispatch(
          databaseActions.updateRestoCategories(
            userState.user.user_id,
            item.company_id,
            item.category_id,
            inputBox1,
            inputBox2,
            0,
            databaseActions.getAreaCategories(
              userState.user.user_id,
              database.currentCompany.id,
              0
            )
          )
        );
      } else {
        dispatch(
          databaseActions.updateRestoCategories(
            userState.user.user_id,
            item.company_id,
            item.category_id,
            inputBox1,
            inputBox2,
            database.currentShop.area_id,
            databaseActions.getAreaCategories(
              userState.user.user_id,
              database.currentCompany.id,
              database.currentShop.area_id
            )
          )
        );
      }
    }
    setEdit(false);
    // setTimeout(() => {
    //   if (database.currentCompany.type === "RETAIL") {
    //     dispatch(
    //       databaseActions.getShopCategories(
    //         database.currentCompany.id,
    //         userState.user.user_id,
    //         database.currentShop.shop_id
    //       )
    //     );
    //   } else {
    //     if (database.currentCompany.direct) {
    //       dispatch(
    //         databaseActions.getAreaCategories(
    //           userState.user.user_id,
    //           database.currentCompany.id,
    //           0
    //         )
    //       );
    //     } else {
    //       dispatch(
    //         databaseActions.getAreaCategories(
    //           userState.user.user_id,
    //           database.currentCompany.id,
    //           database.currentShop.area_id
    //         )
    //       );
    //     }
    //   }
    // }, 1000);
  };

  return (
    <Tr
      textAlign="right"
      className={`${ui.theme === "flame" ? "" : "dark-table-row"}`}
      onDoubleClick={() => {
        setInputBox1(normalAccount);
        setInputBox2(normalAccountDescription);
        setEdit(true);
      }}
    >
      <Td width="10%">{retail ? item.company_id : item.company_id}</Td>
      <Td width="10%">{retail ? item.category_id : item.category_id}</Td>
      <Td width="30%">{item.category_name}</Td>
      {retail && <Td width="10%">{item.parentid}</Td>}
      <Td width="15%">
        {!edit && (
          <div className="row-input-wrapper">
            {inputBox1}
            <div className="flex" />
          </div>
        )}

        {edit && (
          <div className="row-input-wrapper">
            <div>
              <Input
                className="input-wrapper-row-inputbox"
                type="text"
                size="small"
                value={inputBox1}
                onChange={(e) => setInputBox1(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleUpdate();
                  }
                }}
              />
            </div>
          </div>
        )}
      </Td>

      <Td width="25%">
        {!edit && (
          <div className="row-input-wrapper">
            {inputBox2}
            <div className="flex" />
          </div>
        )}

        {edit && (
          <div className="row-input-wrapper">
            <div>
              <Input
                className="input-wrapper-row-inputbox"
                type="text"
                size="small"
                value={inputBox2}
                onChange={(e) => setInputBox2(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleUpdate();
                  }
                }}
              />
            </div>
          </div>
        )}
      </Td>
      <Td width="10%" textAlign="right">
        {!edit && (
          <div className="row-input-wrapper-end">
            <Button
              onClick={(_) => {
                setInputBox1(normalAccount);
                setInputBox2(normalAccountDescription);
                setEdit(true);
              }}
              size="small"
              fill="true"
            >
              Edit
            </Button>
          </div>
        )}

        {edit && (
          <div className="row-input-wrapper-end">
            {changed ? (
              <Button onClick={handleUpdate} size="small" fill="true">
                Update
              </Button>
            ) : inputBox1 === "" && inputBox2 === "" ? null : (
              <Button
                onClick={(_) => {
                  setInputBox1(normalAccount);
                  setInputBox2(normalAccountDescription);
                  setEdit(false);
                }}
                size="small"
                fill="true"
              >
                Cancel
              </Button>
            )}
          </div>
        )}
      </Td>
    </Tr>
  );
};

export default Row;
