import React, { useState, useEffect } from "react";
import { Table, Thead, Tr, Th, Sortable } from "@lightspeed/cirrus-table";
import { Card } from "@lightspeed/flame/Card";
import Row from "./categoriesTable/Row";
import Loader from "../Loader";
import { useSelector } from "react-redux";
import * as sortFns from "../../utils/sortFn";

const CategoriesTable = ({ data, sortState, changeSortState, scrollPos }) => {
  const ui = useSelector((state) => state.ui);
  const database = useSelector((state) => state.database);
  const [sortedData, setSortedData] = useState([...data]);
  const sortValues = ["unsorted", "desc", "asc"];
  const reducer = (accumulator, currentValue) => accumulator + currentValue;

  useEffect(() => {
    setSortedData([...data]);
  }, [data]);

  useEffect(() => {
    if (sortState.reduce(reducer) > 0) {
      // company id
      if (sortState[0] === 1) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(sortFns.customSortGen("company_id")),
        ]);
      } else if (sortState[0] === 2) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(sortFns.customSortGen("company_id")).reverse(),
        ]);
      }

      // category id
      if (sortState[1] === 1) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(
            sortFns.customSortGen(
              database.currentCompany.type === "RETAIL"
                ? "category_id"
                : "category_id"
            )
          ),
        ]);
      } else if (sortState[1] === 2) {
        setSortedData((oldSortedData) => [
          ...oldSortedData
            .sort(
              sortFns.customSortGen(
                database.currentCompany.type === "RETAIL"
                  ? "category_id"
                  : "category_id"
              )
            )
            .reverse(),
        ]);
      }

      // category name
      if (sortState[2] === 1) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(sortFns.customSortGenString("category_name")),
        ]);
      } else if (sortState[2] === 2) {
        setSortedData((oldSortedData) => [
          ...oldSortedData
            .sort(sortFns.customSortGenString("category_name"))
            .reverse(),
        ]);
      }

      // parent id
      if (sortState[3] === 1) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(sortFns.customSortGen("parentid")),
        ]);
      } else if (sortState[3] === 2) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(sortFns.customSortGen("parentid")).reverse(),
        ]);
      }

      // account id
      if (sortState[4] === 1) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(sortFns.customSortGenString("account_id")),
        ]);
      } else if (sortState[4] === 2) {
        setSortedData((oldSortedData) => [
          ...oldSortedData
            .sort(sortFns.customSortGenString("account_id"))
            .reverse(),
        ]);
      }

      // account description
      if (sortState[5] === 1) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(
            sortFns.customSortGenString("account_description")
          ),
        ]);
      } else if (sortState[5] === 2) {
        setSortedData((oldSortedData) => [
          ...oldSortedData
            .sort(sortFns.customSortGenString("account_description"))
            .reverse(),
        ]);
      }
    } else {
      setSortedData([...data]);
    }
  }, [sortState, data, database.currentCompany.type]);

  if (sortedData.length === 0 && ui.search) {
    return (
      <div className="loader-no-item">
        <h3>No items match your search</h3>
      </div>
    );
  }
  if (!sortedData || sortedData.length === 0 || !Array.isArray(sortedData)) {
    return <Loader />;
  }
  return (
    <Card top="true">
      <Table>
        <Thead>
          {scrollPos === true ? (
            <Tr className={`${ui.theme === "flame" ? "" : "dark-table"}`}>
              <Sortable.Th
                direction={sortValues[sortState[0]]}
                onClick={() => {
                  changeSortState(0);
                }}
              >
                Company
              </Sortable.Th>
              <Sortable.Th
                direction={sortValues[sortState[1]]}
                onClick={() => {
                  changeSortState(1);
                }}
              >
                Category Id
              </Sortable.Th>
              <Sortable.Th
                direction={sortValues[sortState[2]]}
                onClick={() => {
                  changeSortState(2);
                }}
              >
                Category Name
              </Sortable.Th>
              {database.currentCompany.type === "RETAIL" && (
                <Sortable.Th
                  direction={sortValues[sortState[3]]}
                  onClick={() => {
                    changeSortState(3);
                  }}
                >
                  Parent
                </Sortable.Th>
              )}
              <Sortable.Th
                direction={sortValues[sortState[4]]}
                onClick={() => {
                  changeSortState(4);
                }}
              >
                Account
              </Sortable.Th>
              <Sortable.Th
                direction={sortValues[sortState[5]]}
                onClick={() => {
                  changeSortState(5);
                }}
              >
                Account Description
              </Sortable.Th>
              <Th textAlign="right">Action</Th>
            </Tr>
          ) : (
            <Tr className={`${ui.theme === "flame" ? "" : "dark-table-fake"}`}>
              <Th className="table-th-sticky">Company</Th>
              <Th className="table-th-sticky">Category Id</Th>
              <Th className="table-th-sticky">Category Name</Th>
              {database.currentCompany.type === "RETAIL" && (
                <Th className="table-th-sticky">Parent</Th>
              )}
              <Th className="table-th-sticky">Account</Th>
              <Th className="table-th-sticky">Account Description</Th>
              <Th className="table-th-sticky" textAlign="right">
                Action
              </Th>
            </Tr>
          )}
        </Thead>
        <tbody>
          {sortedData.map((item) => (
            <Row
              key={
                database.currentCompany.type === "RETAIL"
                  ? item.category_id
                  : item.category_id
              }
              item={item}
              retail={database.currentCompany.type === "RETAIL"}
            />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default CategoriesTable;
