import React from "react";

import CardTable from "./CardTable";
import Loader from "../Loader";
import { useSelector } from "react-redux";

const CardsTable = ({ data }) => {
  const database = useSelector((state) => state.database);
  if (!data || data.length === 0 || !Array.isArray(data)) {
    return <Loader />;
  }

  return (
    <div>
      <CardTable
        title="Tax"
        data={data.filter((item) => item.what === "tax")}
        what="tax"
        shopShow={database.currentCompany.type === "RETAIL" ? true : false}
      />
      <div style={{ marginTop: "32px" }} />
      <CardTable
        title="Over/Short"
        data={data.filter((item) => item.what === "over_short")}
        what="over_short"
        shopShow={database.currentCompany.type === "RETAIL" ? true : false}
      />
      <div style={{ marginTop: "32px" }} />
      <CardTable
        title="Tips"
        data={data.filter((item) => item.what === "tips")}
        what="tips"
        shopShow={database.currentCompany.type === "RETAIL" ? true : false}
      />
       <div style={{ marginTop: "32px" }} />
        <CardTable
          title="CC Fees"
          data={data.filter((item) => item.what === "cc_fee")}
          what="cc_fee"
          shopShow={database.currentCompany.type === "RETAIL" ? true : false}
        />

        <div style={{ marginTop: "32px" }} />
        <CardTable
          title="Miscellaneous"
          data={data.filter((item) => item.what === "misc")}
          what="misc"
          shopShow={database.currentCompany.type === "RETAIL" ? true : false}
        />

        <div style={{ marginTop: "32px" }} />
        <CardTable
          title="Email List (;)"
          data={data.filter((item) => item.what === "email")}
          what="email"
          shopShow={database.currentCompany.type === "RETAIL" ? true : false}
        />

      <div style={{ marginTop: "32px" }} />
        <CardTable
          title="Discount"
          data={data.filter((item) => item.what === "discount")}
          what="discount"
          shopShow={database.currentCompany.type === "RETAIL" ? true : false}
        />


      {data.filter((item) => item.what === "fb_discount").length > 0 && (
        <>
          <div style={{ marginTop: "32px" }} />
          <CardTable
            title="Banquet Service Charge"
            data={data.filter((item) => item.what === "service_charge")}
            what="tips"
            shopShow={database.currentCompany.type === "RETAIL" ? true : false}
          />
          <div style={{ marginTop: "32px" }} />
          <CardTable
            title="Sales Adjustment F&B"
            data={data.filter((item) => item.what === "fb_discount")}
            what="tips"
            shopShow={database.currentCompany.type === "RETAIL" ? true : false}
          />
          <CardTable
            title="Discount"
            data={data.filter((item) => item.what === "discount")}
            what="tips"
            shopShow={database.currentCompany.type === "RETAIL" ? true : false}
          />

 
         

        </>
      )}
    </div>
  );
};

export default CardsTable;
