import React, { useRef, useState, useEffect } from "react";

import PaymentTable from "../tables/PaymentTable";
import TaxesTable from "../tables/TaxesTable";
import CategoriesTable from "../tables/CategoriesTable";
import CategoriesTableExtended from "../tables/CategoriesTableExtended";
import CardsTableMulti from "../tables/CardsTableMulti";
import CategoriesTableRounded from "../tables/CategoriesTableRounded";
import CardsTables from "../tables/CardsTable";
import WithoutCategoriesTable from "../tables/WithoutCategoriesTable";
//import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import { useSelector } from "react-redux";
import Reports from "../tables/Reports";
import TableRenderers from "../ui/reports/TableRenderers"; 
import DummyComponent from "../tables/DummyComponent";



const TableContent = () => {
  const [sortState, setSortState] = useState([0, 0, 0, 0, 0, 0]);
  const [notes, setNotes] = useState([]);
  const [settings, setSettings] = useState({});
  const ui = useSelector((state) => state.ui);
  const database = useSelector((state) => state.database);
  const scrollRef = useRef({ current: 0 });
  const [scrollState, setScrollState] = useState(true);

    /*
    const fetchData = () => {
    //setIsLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
          "token_user_id":1,
          "token_company_id":276410,
          "token_shop_id":1,
          "date_from":"2022-05-25",
          "date_to":"2022-05-27"
      }),
    };

    fetch("https://apimap.datastems.com/api/list/retail/reportXXX", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        //setIsLoading(false);
        setNotes(data);
      })
      .catch((error) => {
        //setIsLoading(false);
        //setIsError(true);
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  
*/
  const handleScroll = () => {
    if (scrollRef.current.scrollTop > 0) {
      if (scrollState !== false) {
        setScrollState(false);
      }
    } else {
      if (scrollState !== true) {
        setScrollState(true);
      }
    }
  };

  const changeSortState = (x) => {
    const newSortState = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    switch (sortState[x]) {
      case 0:
        newSortState[x] = 1;
        break;
      case 1:
        newSortState[x] = 2;
        break;
      default:
        newSortState[x] = 0;
    }
    setSortState([...newSortState]);
  };

  // some logs...
  console.log("Current company type:", database.currentCompany.type);
  console.log("ui.table value:", ui.table);
  console.log("Reports data:", database.currentShop.reports);

  const tables = [
    // Tab 0: Payments (for all company types)
    <PaymentTable
      scrollPos={scrollState}
      sortState={sortState}
      changeSortState={changeSortState}
      data={
        database.currentShop.payments &&
        database.currentShop.payments?.filter(
          (item) =>
            item?.account_id?.toLowerCase().indexOf(ui.search) > -1 ||
            item?.account_description?.toLowerCase().indexOf(ui.search) > -1 ||
            String(item?.paymenttype_id)?.indexOf(ui.search) > -1 ||
            item?.paymenttypeid_name?.toLowerCase().indexOf(ui.search) > -1
        )
      }
    />,

    // Tab 1: Categories (extended for RETAIL only)
    ui.extendTable && database.currentCompany.type === "RETAIL" ? (
      <CategoriesTableExtended
        scrollPos={scrollState}
        sortState={sortState}
        changeSortState={changeSortState}
        data={
          database.currentShop.categories &&
          database.currentShop.categories?.filter(
            (item) =>
              item?.account_id?.toLowerCase().indexOf(ui.search) > -1 ||
              item?.account_description?.toLowerCase().indexOf(ui.search) >
                -1 ||
              (database.currentCompany.type === "RETAIL"
                ? String(item?.category_id)?.indexOf(ui.search) > -1
                : String(item?.category_id)?.indexOf(ui.search) > -1) ||
              item?.category_name?.toLowerCase().indexOf(ui.search) > -1 ||
              String(item?.parentid)?.indexOf(ui.search) > -1 ||
              String(item?.cogs)?.indexOf(ui.search) > -1 ||
              String(item?.cogs_description)?.indexOf(ui.search) > -1
          )
        }
      />
    ) : (
      <CategoriesTable
        scrollPos={scrollState}
        sortState={sortState}
        changeSortState={changeSortState}
        data={
          database.currentShop.categories &&
          database.currentShop.categories?.filter(
            (item) =>
              item?.account_id?.toLowerCase().indexOf(ui.search) > -1 ||
              item?.account_description?.toLowerCase().indexOf(ui.search) >
                -1 ||
              (database.currentCompany.type === "RETAIL"
                ? String(item?.category_id)?.indexOf(ui.search) > -1
                : String(item?.category_id)?.indexOf(ui.search) > -1) ||
              item?.category_name?.toLowerCase().indexOf(ui.search) > -1 ||
              String(item?.parentid)?.indexOf(ui.search) > -1
          )
        }
      />
    ),

    // Tab 2: Categories (Multi GL) for RETAIL
    <CardsTableMulti data={database.currentShop.categoriesMulti} />,

    // Tab 3: Categories (Rounded) for RETAIL
    <CategoriesTableRounded
      scrollPos={scrollState}
      sortState={sortState}
      changeSortState={changeSortState}
      data={
        database.currentShop.categoriesRound &&
        database.currentShop.categoriesRound?.filter(
          (item) =>
            item?.account_id?.toLowerCase().indexOf(ui.search) > -1 ||
            item?.account_description?.toLowerCase().indexOf(ui.search) > -1 ||
            String(item?.category_id)?.indexOf(ui.search) > -1 ||
            item?.category_name?.toLowerCase().indexOf(ui.search) > -1 ||
            String(item?.parentid)?.indexOf(ui.search) > -1
        )
      }
    />,

    // Tab 4: Cards Tables for both RETAIL and RESTO
    <CardsTables data={database.currentShop.other} />,

    // Tab 5: Without Categories Table for RETAIL
    <WithoutCategoriesTable
      scrollPos={scrollState}
      sortState={sortState}
      changeSortState={changeSortState}
      data={
        database.currentShop.less &&
        database.currentShop?.less?.filter(
          (item) =>
            item?.description?.toLowerCase().indexOf(ui.search) > -1 ||
            item?.account_description?.toLowerCase().indexOf(ui.search) > -1 ||
            String(item?.category_id)?.indexOf(ui.search) > -1 ||
            item?.category_name?.toLowerCase().indexOf(ui.search) > -1 ||
            String(item?.itemid)?.indexOf(ui.search) > -1
        )
      }
    />,

    
    // Tab 6: Reports component for RETAIL and RETAILX
    database.currentCompany.type === "RETAIL" || database.currentCompany.type === "RETAILX" ? (
      <Reports
        scrollPos={scrollState}
        sortState={sortState}
        changeSortState={changeSortState}
        data={database.currentShop.reports}
        rows={['transaction_date', 'gl_number', 'gl_description', 'debit', 'credit', 'total']}
        aggregatorName="Sum"
        vals={["total"]}
        renderers={TableRenderers}
        hiddenAttributes={["pvtRenderers", "pvtAxisContainer", "pvtVals", "pvtAxisContainer"]}
      />
    ) : null,

    // Tab 7: Taxes Table for both RETAIL and RESTO
    <TaxesTable
      scrollPos={scrollState}
      sortState={sortState}
      changeSortState={changeSortState}
      data={
        database.currentShop.taxes &&
        database.currentShop.taxes?.filter(
          (item) =>
            item?.account_id?.toLowerCase().indexOf(ui.search) > -1 ||
            item?.account_description?.toLowerCase().indexOf(ui.search) > -1 ||
            String(item?.tax_id)?.indexOf(ui.search) > -1 ||
            item?.tax_name?.toLowerCase().indexOf(ui.search) > -1
        )
      }
    />,

    // Reports component for RESTO/RESTOK (tab 8)
    (database.currentCompany.type === "RESTO" || database.currentCompany.type === "RESTOK") && ui.table === 8 ? (
     <Reports />
    ) : null,
      
  ];
  return (
    <div className="table-content" onScroll={handleScroll} ref={scrollRef}>
      {tables[ui.table]}
    </div>
  );
};

export default TableContent;
