import React, { useState } from "react";
import { Card } from "@lightspeed/flame/Card";

import { ReactComponent as Location } from "../../../assets/location.svg";

import * as databaseActions from "../../../redux/actions/databaseActions";
import { useDispatch } from "react-redux";

// ... ostatak koda

const LocationButton = ({ location, company, shop }) => {
  const dispatch = useDispatch();
  const [isChecked, setChecked] = useState(false);

  let active = false;
  let handleCardClick;

  //console.log("PRINT 2 **company.type **", company.type);

  /*
  PROMJENA
  */
  //console.log("TU SAM 12")
  if (company.type === "RETAIL") {
    console.log("TU SAM 101")
    //console.log("PRINT RETAIL **company.type **", company.type);
    active = location.shop_id === shop.shop_id;
    handleCardClick = () => {
      dispatch(databaseActions.setCurrentShopId(location.shop_id));
      dispatch(databaseActions.getCurrentRetailShop(company.id, location.shop_id));
    };
    
  } else if (company.type === "RETAILX") {
    console.log("TU SAM 102")
    //console.log("PRINT RETAILX **company.type **", company.type);
    active = location.shop_id === shop.shop_id;
    handleCardClick = () => {
      dispatch(databaseActions.setCurrentShopIdX(location.shop_id));
      dispatch(databaseActions.getCurrentRetailShopX(company.id, location.shop_id));
    };

  } else if (company.type === "RESTO") {
    console.log("TU SAM 103")
    active = location.area_id === shop.area_id;
    handleCardClick = () => {
      dispatch(databaseActions.setCurrentAreaId(location.area_id));
      dispatch(databaseActions.getCurrentRestoArea(company.id, location.area_id));
    };
  } else if (company.type === "RESTOK") {
    console.log("TU SAM 104")
    active = location.area_id === shop.area_id;
    handleCardClick = () => {
      dispatch(databaseActions.setCurrentAreaIdK(location.area_id));
      dispatch(databaseActions.getCurrentRestoAreaK(company.id, location.area_id));
    };
  }

  const dynamicClass = active ? "inverted" : "";

  const handleRadioChange = () => {
    setChecked(!isChecked); // Toggle radio button state
    // Handle radio button-specific logic here
  };

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default context menu
    setChecked(!isChecked); // Toggle radio button state on right-click
    // Handle right-click-specific logic here
  };

  return (
    <Card
      onClick={handleCardClick}
      onContextMenu={handleContextMenu}
      top="true"
      className={`location-button ${dynamicClass}`}
    >
      <div>
        <Location />
        {location.shop_name ? location.shop_name : location.area_name}
      </div>
      {company.type === "RESTO" && (
        <label>
          <input
            type="radio"
            name="locationRadioGroup"
            checked={isChecked}
            onChange={handleRadioChange}
          />
          Default
        </label>
      )}
    </Card>
  );
};

export default LocationButton;
