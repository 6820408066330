import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import "./pivottable-theme.css"; 
import { Card } from "@lightspeed/flame/Card";
import styled from "@emotion/styled";
import TableRenderers from "./TableRenderers";
import * as XLSX from 'xlsx'; // handling excel export

const StyledCard = styled(Card)`
  flex-grow: 1;
  position: relative;
  overflow: auto;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

// wrapped in forwardRef to pass ref from parent (Reports.js) to child (Table.js)
const Table = forwardRef(({ data, rows, cols, aggregatorName, vals, rendererName }, ref) => {
  const [state, setState] = useState({
    rows: [], 
    cols: [], 
    aggregatorName: "Sum", // default aggregator
    vals: [], 
    rendererName: "Table", 
  });

  // define all possible fields expected in data
  const allFields = [
    "transaction_date",
    "account_id",
    "account_description",
    "debit",
    "credit",
    "posting_comment",
    "xoption",
  ];

  // update state when data is ready
  useEffect(() => {
    if (data && data.length > 0) {
      // all possible columns/fields are present even if data didnt fetch
      const normalizedData = data.map((row) => {
        // fill them with null
        allFields.forEach((field) => {
          if (!(field in row)) {
            row[field] = null;
          }
        });
        return row;
      });

      setState((prevState) => ({
        ...prevState,
        rows, 
        cols, 
        aggregatorName, 
        vals, 
        rendererName, 
        data: normalizedData, // set data
      }));
    } else {
      // when no data, create placeholder row with all fields set to null
      const placeholderRow = {};
      allFields.forEach((field) => (placeholderRow[field] = null));

      setState((prevState) => ({
        ...prevState,
        rows, 
        cols, 
        aggregatorName, 
        vals, 
        rendererName, 
        data: [placeholderRow], // use a placeholder row for data
      }));
    }
  }, [data, rows, cols, aggregatorName, vals, rendererName]);

  // Expose the export method to the parent (Reports.js)
  useImperativeHandle(ref, () => ({
    export: () => {
      // current config from the PivotTable state
      const { rows, cols } = state;
  
      // merge rows and columns arrays to get fields that should be included in the export
      const fieldsToExport = [...rows, ...cols];
  
      // filter data to have only selected fields
      const exportData = data.map(row => {
        let rowData = {};
  
        // only include fields in the current PivotTable configuration
        fieldsToExport.forEach(field => {
          rowData[field] = row[field] || null; // set undefined fields to null to handle missing data
        });
  
        return rowData;
      });
  
      // conversion to Excel
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
  
      // download the file
      XLSX.writeFile(workbook, "report.xlsx");
    },
    exportToCSV: () => {
      const { rows, cols } = state;
      const fieldsToExport = [...rows, ...cols];

      const exportData = data.map(row => {
        let rowData = {};
        fieldsToExport.forEach(field => {
          rowData[field] = row[field] || null;
        });
        return rowData;
      });

      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const csv = XLSX.utils.sheet_to_csv(worksheet);
      
      // create BLOB for download
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "report.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getColumns: () => {
      const { rows, cols } = state;
      return [...rows, ...cols]; // current rows and columns for updating report settings
    }
  }));

  return (
    <StyledCard>
      <Container>
        <PivotTableUI
          unusedOrientationCutoff={Infinity}
          data={data.length > 0 ? data : [{}]} // empty object if no data to keep table structure
          onChange={(s) => setState(s)} 
          renderers={TableRenderers} 
          {...state} // current state for PivotTable configuration
        />
      </Container>
    </StyledCard>
  );
});

export default Table;
