import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab, TabContainer } from "@lightspeed/flame/Tab";
import { Card } from "@lightspeed/flame/Card";
import { Input } from "@lightspeed/flame/Input";
import { Button } from "@lightspeed/flame/Button";
import * as databaseActions from "../../redux/actions/databaseActions";
import { toggleExtend } from "../../redux/actions/uiActions";
import { setCurrentTable, setSearch } from "../../redux/actions/uiActions";

const CategorieBar = () => {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const database = useSelector((state) => state.database);
  const userState = useSelector((state) => state.userState);

  // valid tabs by companies on CategorieBar
  const validTabsByCompanyType = {
    RETAIL: [0, 1, 2, 3, 4, 5, 6, 7], 
    RETAILX: [0, 1, 2, 3, 4, 5, 6, 7], 
    RESTO: [0, 1, 4, 7, 8], 
    RESTOK: [0, 1, 4, 7, 8], 
  };

  
  // is selected tab valid? (if previous tab is selected on new location reset to first valid tab)
  useEffect(() => {
    const validTabs = validTabsByCompanyType[database.currentCompany.type] || [0]; // default is first (Payments) tab if type not found
    
    // is currently selected tab valid for this company type?
    if (!validTabs.includes(ui.table)) {
      // reset to first valid tab 
      dispatch(setCurrentTable(validTabs[0]));
    }
  }, [database.currentCompany.type, ui.table, dispatch]);
  

  //console.log("PROMJENA")
  //console.log("database.currentCompany.type XXX2", database.currentCompany.type)
  const shopTabContainer = (
    <TabContainer>
      <Tab
        active={ui.table === 0}
        onClick={(_) => {
          if (ui.table !== 0) {
            dispatch(setCurrentTable(0));
          }
          
          dispatch(databaseActions.setShopPayments([]));

          if (database.currentCompany.type === "RETAILX") {
              console.log("Payment Retail X - CLICK ON PAYMENT TAB")
              dispatch(
                databaseActions.getShopPaymentsX(
                  database.currentCompany.id,
                  userState.user.user_id,
                  database.currentShop.shop_id
                )
              );
            } else 
            {
              console.log("Payment Retail R - CLICK ON PAYMENT TAB")
              dispatch(
                databaseActions.getShopPayments(
                  database.currentCompany.id,
                  userState.user.user_id,
                  database.currentShop.shop_id
                )
              );
            }

          
        }}
      >
        Payments
      </Tab>
      <Tab
        active={ui.table === 1}
        onClick={(_) => {
          if (ui.table !== 1) {
            dispatch(setCurrentTable(1));
          }
          dispatch(databaseActions.setShopCategories([]));
          if (database.currentCompany.type === "RETAILX") {
            dispatch(
            databaseActions.getShopCategoriesX(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
            );
          } else 
          {
            dispatch(
            databaseActions.getShopCategories(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
            );
          }
        }}
      >
        Categories
      </Tab>
      <Tab
        active={ui.table === 2}
        onClick={(_) => {
          if (ui.table !== 2) {
            dispatch(setCurrentTable(2));
          }
          dispatch(databaseActions.setShopCategoriesMulti([]));
          dispatch(
            databaseActions.getShopCategoriesMulti(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
          );
        }}
      >
        Categories (Multi GL)
      </Tab>
      <Tab
        active={ui.table === 3}
        onClick={(_) => {
          if (ui.table !== 3) {
            dispatch(setCurrentTable(3));
          }
          dispatch(databaseActions.setShopCategoriesRound([]));
          if (database.currentCompany.type === "RETAILX") {
            dispatch(
            databaseActions.getShopCategoriesRoundX(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
            );
          } else 
          {
            dispatch(
            databaseActions.getShopCategoriesRound(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
            );
          }
        }}
      >
        Categories (round)
      </Tab>
      <Tab
        active={ui.table === 4}
        onClick={(_) => {
          if (ui.table !== 4) {
            dispatch(setCurrentTable(4));
          }
          dispatch(databaseActions.setShopCategoriesOther([]));
          if (database.currentCompany.type === "RETAILX") {
            console.log("Payment Retail X - CLICK ON Categories TAB")
            dispatch(
            databaseActions.getShopCategoriesOtherX(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
            );
          } else 
          {
            console.log("Payment Retail R - CLICK ON Categories TAB")
            dispatch(
            databaseActions.getShopCategoriesOther(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
            );
          }
        }}
      >
        Other (Over/Short,Tips) retail
        
      </Tab> 
    
      <Tab
        active={ui.table === 5}
        onClick={(_) => {
          if (ui.table !== 5) {
            dispatch(setCurrentTable(5));
          }
          dispatch(databaseActions.setShopCategoriesLess([]));
          if (database.currentCompany.type === "RETAILX") {
            dispatch(
            databaseActions.getShopCategoriesLessX(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
            );
          } else 
          {
            dispatch(
            databaseActions.getShopCategoriesLess(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
            );
          }
        }}
      >
        Items without category
      </Tab>
      <Tab
        active={ui.table === 6}
        onClick={(_) => {
          if (ui.table !== 6) {
            dispatch(setCurrentTable(6));
          }
          console.log("Report Retail click")
          dispatch(databaseActions.setShopReport([]));
          // no need to call databaseAction.js getReportsXX or XXX when Report Retail tab is clicked
         /* if (database.currentCompany.type === "RETAILX") {
            dispatch(
            databaseActions.getReportsXXX(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
            );
          } else 
          {
            dispatch(
            databaseActions.getReportsXX(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
            );
          }*/
        }}
      >
        Report Retail
        
        
      </Tab>

      <Tab
        active={ui.table === 7}
        onClick={(_) => {
          if (ui.table !== 7) {
            dispatch(setCurrentTable(7));
          }
          if (database.currentCompany.type === "RETAILX") {
            console.log("Payment Retail X - CLICK ON TAX TAB")
            dispatch(databaseActions.setShopTaxesX([]));
            dispatch(
              databaseActions.getShopTaxesX(
                database.currentCompany.id,
                userState.user.user_id,
                database.currentShop.shop_id
              )
            );
          } else
          {
            console.log("Payment Retail R - CLICK ON TAX TAB")
            dispatch(databaseActions.setShopTaxes([]));
            dispatch(
              databaseActions.getShopTaxes(
                database.currentCompany.id,
                userState.user.user_id,
                database.currentShop.shop_id
              )
            );
          };
        }}
      >
        Taxes
      </Tab>
    </TabContainer>
  );



  const areaTabContainer = (
    <TabContainer>
      <Tab
        active={ui.table === 0}
        onClick={(_) => {
          if (ui.table !== 0) {
            dispatch(setCurrentTable(0));
          }
          dispatch(databaseActions.setAreaPayments([]));

          //console.log(database.currentCompany.type)
          /*
          PROMJENA
          */

          console.log ("database.currentCompany.type 1 ", database.currentCompany.type)
          

          if (database.currentCompany.type === "RESTOK") {
            dispatch(
              databaseActions.getAreaPaymentsK(
              userState.user.user_id,
              database.currentCompany.direct
                ? database.currentShop.company_id
                : database.currentCompany.id,
              database.currentCompany.direct ? 0 : database.currentShop.area_id
            )
          );
          } else 
          {
            console.log("AA3") 
            dispatch(
                databaseActions.getAreaPayments(
                userState.user.user_id,
                database.currentCompany.direct
                  ? database.currentShop.company_id
                  : database.currentCompany.id,
                database.currentCompany.direct ? 0 : database.currentShop.area_id
              )
            );
          }
        }}
      >
        Payments
      </Tab>
      
      <Tab
        active={ui.table === 1}
        onClick={(_) => {
          if (ui.table !== 1) {
            dispatch(setCurrentTable(1));
          }
          if (database.currentCompany.type === "RESTOK") {
            dispatch(databaseActions.setAreaCategoriesK([]));
            dispatch(
              databaseActions.getAreaCategoriesK(
                userState.user.user_id,
                database.currentCompany.direct
                  ? database.currentShop.company_id
                  : database.currentCompany.id,
                database.currentCompany.direct ? 0 : database.currentShop.area_id
              )
            );
          } else  {
            dispatch(databaseActions.setAreaCategories([]));
            dispatch(
              databaseActions.getAreaCategories(
                userState.user.user_id,
                database.currentCompany.direct
                  ? database.currentShop.company_id
                  : database.currentCompany.id,
                database.currentCompany.direct ? 0 : database.currentShop.area_id
              )
            );
          }
        }}
      >
        Categories
      </Tab>
      <Tab
        active={ui.table === 4}
        onClick={(_) => {
          if (ui.table !== 4) {
            dispatch(setCurrentTable(4));
          }
          if (database.currentCompany.type === "RESTOK") {
            dispatch(databaseActions.setAreaCategoriesOtherK([]));
            dispatch(
              databaseActions.getAreaCategoriesOtherK(
                userState.user.user_id,
                database.currentCompany.direct
                  ? database.currentShop.companycompany_idid
                  : database.currentCompany.id,
                database.currentCompany.direct ? 0 : database.currentShop.area_id
              )
            );
          } else {
            dispatch(databaseActions.setAreaCategoriesOther([]));
            dispatch(
              databaseActions.getAreaCategoriesOther(
                userState.user.user_id,
                database.currentCompany.direct
                  ? database.currentShop.companycompany_idid
                  : database.currentCompany.id,
                database.currentCompany.direct ? 0 : database.currentShop.area_id
              )
            );
              }
        }}
      >
        Other (Over/Short,Tips) resto
      </Tab>
      <Tab
        active={ui.table === 7}
        onClick={(_) => {
          if (ui.table !== 7) {
            dispatch(setCurrentTable(7));
          }
          if (database.currentCompany.type === "RESTOK") {
            dispatch(databaseActions.setAreaTaxesK([]));
            dispatch(
              databaseActions.getAreaTaxesK(
                userState.user.user_id,
                database.currentCompany.direct
                  ? database.currentShop.company_id
                  : database.currentCompany.id,
                database.currentCompany.direct ? 0 : database.currentShop.area_id
              )
            );
          } else {
              dispatch(databaseActions.setAreaTaxes([]));
              dispatch(
                databaseActions.getAreaTaxes(
                  userState.user.user_id,
                  database.currentCompany.direct
                    ? database.currentShop.company_id
                    : database.currentCompany.id,
                  database.currentCompany.direct ? 0 : database.currentShop.area_id
                )
              );
                }
        }}
      >
        Taxes (Resto)
      </Tab>

    {/* NEW Report Retail Tab for RESTO */}
    <Tab
      active={ui.table === 8}  //ui.table 8 for Report Restaurant 
      onClick={(_) => {
        if (ui.table !== 8) {
          dispatch(setCurrentTable(8));  // current tab = Report Restaurant for RESTO
        }
        console.log("Report Retail click for RESTO");
        dispatch(databaseActions.setShopReport([]));  
      }}
    >
      {database.currentCompany.type === "RESTOK"
    ? "Report Restaurant (K)"
    : database.currentCompany.type === "RESTO"
    ? "Report Restaurant (L)"
    : "Report Restaurant"}
    </Tab>

    </TabContainer>
  );

  //PROMJENA
  return (
    <Card
      className={`categories-bar ${
        ui.theme === "flame" ? "" : "categories-bar-dark"
      }`}
    >
      
      {database.currentCompany.type === "RETAIL" || database.currentCompany.type === "RETAILX"
        ? shopTabContainer
        : areaTabContainer
      }

      <div style={{ flex: "1" }} />
      {(database.currentCompany.type === "RETAIL" || database.currentCompany.type === "RETAILX") && ui.table === 1 && (
        <Button
          style={{ marginRight: "8px" }}
          size="small"
          fill="true"
          onClick={() => dispatch(toggleExtend())}
        >
          Extend
        </Button>
      )}
      <div style={{ marginRight: "8px" }}>
        <Input
          value={ui.search}
          onChange={(e) => dispatch(setSearch(e.target.value))}
          type="text"
          size="small"
          placeholder="Search"
          width="250px"
        />
      </div>
    </Card>
  );
};

export default CategorieBar;
