import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import GlobalError from "./GlobalError";

//css
import "./css/root.css";
import "./css/index.css";
import "./css/login.css";
import "./css/sidebar.css";
import "./css/location.css";
import "./css/dark.css";
import "./css/loader.css";

//redux toolkit
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers/rootReducer";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; 
import { PersistGate } from "redux-persist/integration/react";

const persistConfig = {
  key: "root",
  storage,
};


const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // ignore these actions for serializable 
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE", "persist/PURGE"],
      },
    }),
});;

//const persistor = persistStore(store);
export const persistor = persistStore(store); // export persistor to provide instance to UserDisplay.js (UserDipaly)


const originalError = console.error;
console.error = (...args) => {
  if (
    args[0] &&
    typeof args[0] === 'string' &&
    args[0].includes("Failed prop type: The prop `attrValues` is marked as required")
  ) {
    return; // Ignore this specific warning
  }
  originalError(...args); // log as usual
};

ReactDOM.render(
  <React.StrictMode>
    <GlobalError>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </GlobalError>
  </React.StrictMode>,
  document.getElementById("root")
);
