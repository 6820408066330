import Table from "../ui/reports/Table";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import DatePicker from "../ui/reports/DatePicker";
//import { ClipLoader } from "react-spinners";

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
`;

const Reports = () => {
  const [data, setData] = useState([]); // state for fetched data
  //const [loading, setLoading] = useState(false); // loading state for sync
  const [reportColumns, setReportColumns] = useState(null); // state for report columns
  const tableRef = useRef();
  const [selectedReportId, setSelectedReportId] = useState(null);  
  const [selectedReportName, setSelectedReportName] = useState(""); 


  // Get user_id, company_id from Redux
  const userState = useSelector((state) => state.userState);
  const userId = userState.user.user_id;
  const companyId = useSelector((state) => state.database.currentCompany.id);
  const shopId = useSelector((state) => state.database.currentShop.shop_id);
  const companyType = useSelector((state) => state.database.currentCompany.type); // company type (RETAIL, RESTO, etc)
  const areaId = useSelector((state) => state.database.currentShop.area_id);



  // Log userId, companyId, and shopId
  useEffect(() => {
    console.log("User ID:", userId, "Company ID:", companyId, "Shop ID:", shopId);
  }, [userId, companyId, shopId]);

  useEffect(() => {
    console.log("Rendering Reports Component for RESTO");
  }, []);
  
  //was:
  const rows = ["transaction_date", "account_description", "debit", "credit"];

  const defaultRows = ["transaction_date", "account_description", "debit", "credit"]; // default rows when no Report selected
  const cols = [];
  const aggregatorName = "Sum";
  const vals = ["debit_credit_amount"];
  const rendererName = "Table";

  // API endpoint based on the company type (Retail or Restaurant)
  const getApiUrl = () => {
    if (companyType === "RESTOK") {
      return "https://mapapimssql.datastems.com/api/admin/restok/report";
    } else if (companyType === "RESTO" || companyType === "RESTOL") {
      return "https://mapapimssql.datastems.com/api/admin/restol/report";
    } else {
      return "https://mapapimssql.datastems.com/api/admin/retailr/report";
    }
  };

  // fetch data from API
  const fetchData = async (fromDate, toDate, reportName, reportId) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        token_user_id: userId,
        company_id: companyId,
        //shopId, 
        //if RESTOK, use areaId as shop_id, otherwise shopId
        shop_id: companyType === 'RESTOK' || companyType === 'RESTO' ? areaId : shopId,
        register_id: 1,
        from_date: fromDate,  // fromDate from input
        to_date: toDate, // toDate from input
        xoption: "",
        report_id: selectedReportId, 
        report_name: selectedReportName,
        //area_id: areaId ? areaId : null,      
      }),
    };

    try {
      const response = await fetch(getApiUrl(),  requestOptions);
      const data = await response.json();
      setData(data.results);
    } catch (error) {
      console.error(error);
    } finally {
      //setLoading(false); // hide loading spinner
    }
  };

  return (
    <Wrapper>
      <Table
        ref={tableRef}
        data={data}
        // was: rows={rows}
        rows={reportColumns && reportColumns.length > 0 ? reportColumns : defaultRows} // defualt or columns accordig to selected Report
        cols={cols}
        aggregatorName={aggregatorName}
        vals={vals}
        rendererName={rendererName}
      />
      <DatePicker // parent component in use for DatePicker
        updateAction={(fromDate, toDate) => fetchData(fromDate, toDate)}
        tableRef={tableRef}
        userId={userId}  // pass userId to DatePicker which contains select list where its used
        companyId={companyId}  // pass companyId to DPicker
        companyType={companyType}
        onReportChange={(columns, reportId, reportName) => {
         setReportColumns(columns);
         setSelectedReportId(reportId);  // selcted report_id (id)
         setSelectedReportName(reportName);  //selected report_name
        }}
      />
    </Wrapper>
  );
};

export default Reports;
