import React, { useState } from "react";
import { Button } from "@lightspeed/flame/Button";
import { Input } from "@lightspeed/flame/Input";
import { LoginWrapper } from "../LoginWrapper";

// import { useDispatch } from "react-redux";

// import { getUser } from "../../redux/actions/userActions";
// import { useHistory } from "react-router-dom";

const Recover = () => {
  // const { push } = useHistory();

  // const dispatch = useDispatch();
  const [userValue, setUserValue] = useState("");
  const [notification, setNotification] = useState({ show: false, msg: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userValue === "") {
      setNotification({ show: true, msg: "Username is required" });
      return;
    }

    // dispatch();
  };
  return (
    <LoginWrapper>
      <form className="login-form" onSubmit={handleSubmit}>
        <label htmlFor="user">Username</label>
        <Input
          name="user"
          type="text"
          value={userValue}
          placeholder="Username"
          onChange={(e) => setUserValue(e.target.value)}
          tabIndex="1"
        />

        {notification.show && <p className="form-notifi">{notification.msg}</p>}

        <div className="fl-mb-2">
          <Button
            style={{ marginTop: "32px" }}
            block="false"
            type="submit"
            fill="true"
            variant="primary"
            tabIndex="3"
          >
            Recover
          </Button>
        </div>
      </form>
    </LoginWrapper>
  );
};

export default Recover;
