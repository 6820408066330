import React from "react";
import { useSelector } from "react-redux";

import logoOld from "../assets/DataStems_Left_Justified.png";
import logo from "../assets/DataStems_Left_Justified.png";
export const LoginWrapper = ({ children }) => {
  const ui = useSelector((state) => state.ui);

  return (
    <div className="login-wrapper">
      <div className={`login-box-bg ${ui.theme === "flame" ? "" : "login-box-bg-dark"}`}>
        <div className={`login-box ${ui.theme === "flame" ? "" : "login-box-dark"}`}>
          <div className={`logo-wrapper ${ui.theme === "flame" ? "" : "logo-wrapper-dark"}`}>
            <img src={ui.theme === "flame" ? logoOld : logo} alt="logo" />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
