import React from "react";
import { Divider } from "@lightspeed/flame/Divider";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { setCurrentTable } from "../../../../redux/actions/uiActions";

import * as databaseActions from "../../../../redux/actions/databaseActions";

const SideListItem = ({ item, listN, setDashMain }) => {
  const database = useSelector((state) => state.database);
  const userState = useSelector((state) => state.userState);
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const { area_visible, company_id, company_name, url_link } = item;

  const dynamicClass =
  
    listN === 1
      ? company_id === database.currentCompany.id
        ? "side-list-active"
        : ""
      : area_visible
      ? company_id === database.currentCompany.id
        ? "side-list-active"
        : ""
      : company_id === database.currentShop.company_id
      ? "side-list-active"
      : "";
  
  function handleLinkClick(event) {
    if (!url_link) {
      event.preventDefault();
      //alert("Link is empty");
    }
  }

  return (
    <div
      onClick={(_) => {
        setDashMain(false);

        console.log("PRINT 1 **listN **", listN )
        console.log("PRINT 2 **listN **", company_id )
        /*
        PROMJENA
        */
        dispatch(setCurrentTable(0)); // reset to first tab

        if (listN === 1) {
          dispatch(databaseActions.setCurrentCompanyId(company_id));
          dispatch(databaseActions.getCurrentRetail(company_id, userState.user.user_id));
        } // 1

        if (listN === 11) {
            dispatch(databaseActions.setCurrentCompanyType("RETAILX"));
            dispatch(databaseActions.setCurrentShopCompanyId(company_id));
            dispatch(databaseActions.setCurrentShopCompanyIdX(company_id));
            dispatch(databaseActions.getCurrentRetailX(company_id, userState.user.user_id));
        } // 11


        if (listN === 9) {
          if (area_visible) {
            dispatch(databaseActions.setCurrentCompanyType("RESTOK"));
            dispatch(databaseActions.setCurrentCompanyId(company_id));
            dispatch(databaseActions.setCurrentRestoDirect(false));
          } else {
            dispatch(databaseActions.setCurrentCompanyType("RESTOK"));
            dispatch(databaseActions.setCurrentShopCompanyId(company_id));
            dispatch(databaseActions.setCurrentRestoDirect(true));
          }

          dispatch(
            databaseActions.getCurrentRestoK(
              userState.user.user_id,
              company_id,
              area_visible
            )
          );

        } //9


        if (listN === 2) {
          if (ui.table === 2 || ui.table === 3 || ui.table === 5) {
            dispatch(setCurrentTable(0));
          }

          if (area_visible) {
            dispatch(databaseActions.setCurrentCompanyId(company_id));
            dispatch(databaseActions.setCurrentRestoDirect(false));
          } else {
            console.log("TU SAM 10")
            dispatch(databaseActions.setCurrentCompanyType("RESTO"));
            dispatch(databaseActions.setCurrentShopCompanyId(company_id));
            dispatch(databaseActions.setCurrentRestoDirect(true));
          }

          console.log("TU SAM 11")
          dispatch(
            databaseActions.getCurrentResto(
              userState.user.user_id,
              company_id,
              area_visible
            )
          );
        } // 2
    
      }}
      className={`side-list ${dynamicClass}`}
    >
        <p>
          <a
            href={url_link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleLinkClick}
          >
            {company_name}
          </a>
      </p>

      <Divider />
    </div>
  );
};

export default SideListItem;
