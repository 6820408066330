import React from "react";
import { Button } from "@lightspeed/flame/Button";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { persistor } from "../../../index"; 

import user from "../../../assets/user.svg";

const UserDispaly = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    // dispatch logout action to reset Redux state
    dispatch({ type: "USER_LOGOUT" });
  
    // clear state
    persistor.purge();
  
    // redirect to login page
    history.push("/login"); 
  };

  return (
    <div className="user-display">
      <div className="user-svg-wrapper">
        <img src={user} alt="user" />
      </div>
      <div className="flex" />
      <Button onClick={handleLogout}>Logout</Button>
    </div>
  );
};

export default UserDispaly;
