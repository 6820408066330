import React from "react";

import { Td, Tr } from "@lightspeed/cirrus-table";

import { useSelector } from "react-redux";
const Row = ({ item }) => {
  const ui = useSelector((state) => state.ui);

  return (
    <Tr
      textAlign="right"
      className={`${ui.theme === "flame" ? "" : "dark-table-row"}`}
    >
      <Td width="13%">{item.company_id}</Td>
      <Td width="10%">{item.itemid}</Td>
      <Td width="10%">{item.category_id}</Td>
      <Td width="30%">{item.description}</Td>

      <Td width="15%">{item.category_name}</Td>
      <Td width="25%" textAlign="right">
        {item.account_description}
      </Td>
    </Tr>
  );
};

export default Row;
