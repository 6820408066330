import React, { useState } from "react";
import { Button } from "@lightspeed/flame/Button";
import { Input } from "@lightspeed/flame/Input";

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { createUser } from "../../redux/actions/userActions";
import { useHistory } from "react-router-dom";
import { LoginWrapper } from "../LoginWrapper";

const Register = ({ userValue, setUserValue, passwordValue, setPasswordValue }) => {
  const dispatch = useDispatch();
  const [companyValue, setCompanyValue] = useState("");
  const [passwordValue2, setPasswordValue2] = useState("");
  const [notification, setNotification] = useState({ show: false, msg: "" });

  const history = useHistory(); // Get the history object

  const onCreate = () => {
    history.push("/login");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userValue === "") {
      setNotification({ show: true, msg: "Username is required" });
      return;
    }
    if (companyValue === "") {
      setNotification({ show: true, msg: "Company is required" });
      return;
    }
    if (passwordValue === "") {
      setNotification({ show: true, msg: "Password is required" });
      return;
    }
    if (passwordValue2 === "") {
      setNotification({ show: true, msg: "Confirm Password is required" });
      return;
    }
    if (passwordValue !== passwordValue2) {
      setNotification({ show: true, msg: "Passwords do not match" });
      return;
    }

    dispatch(
      createUser({
        username: userValue,
        password: passwordValue,
        company: companyValue,
        onCreate,
        setNotification,
      })
    );
  };
  return (
    <LoginWrapper>
      <form className="login-form" onSubmit={handleSubmit}>
        <label htmlFor="user">Username</label>
        <Input
          name="user"
          type="email"
          value={userValue}
          autoComplete="username"
          placeholder="Username"
          onChange={(e) => setUserValue(e.target.value)}
          tabIndex="1"
        />
        <label htmlFor="user">Company</label>
        <Input
          name="company"
          type="text"
          autoComplete="company"
          value={companyValue}
          placeholder="Company"
          onChange={(e) => setCompanyValue(e.target.value)}
          tabIndex="2"
        />
        <div className="password-recover-wrapper">
          <label htmlFor="password">Password</label>
          <Link tabIndex="4" to="/recover">
            Forgot password?
          </Link>
        </div>
        <Input
          name="password"
          type="password"
          autoComplete="new-password"
          value={passwordValue}
          onChange={(e) => setPasswordValue(e.target.value)}
          tabIndex="3"
        />

        <label htmlFor="password2">Confirm Password</label>
        <Input
          name="password2"
          type="password"
          autoComplete="new-password"
          value={passwordValue2}
          onChange={(e) => setPasswordValue2(e.target.value)}
          tabIndex="4"
        />

        {notification.show && <p className="form-notifi">{notification.msg}</p>}

        <div className="fl-mb-2">
          <Button
            style={{ marginTop: "48px" }}
            block="false"
            type="submit"
            fill="true"
            variant="primary"
            tabIndex="10"
          >
            Create account
          </Button>
          <Button
            style={{ marginTop: "16px" }}
            block={true} // Updated from "true" to {true}
            type="button"
            fill=""
            variant="primary"
            tabIndex="11"
            onClick={(e) => {
              e.preventDefault();
              //history.push("/login");
            }}
          >
            Log Into Your Account
          </Button>
          <label htmlFor="version">Version: v2.0</label>
        </div>
      </form>
    </LoginWrapper>
  );
};

export default Register;
