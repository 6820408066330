import React from "react";

import Report from "./dash/Report";
import DarkReport from "./dash/DarkReport";

import { Card } from "@lightspeed/flame/Card";

import { useSelector } from "react-redux";

const Dash = () => {
  const userState = useSelector((state) => state.userState);
  const ui = useSelector((state) => state.ui);

  return (
    <Card className="dash">
      <h2>Welcome {userState.user.user}</h2>
      <p>
        USER ID: {userState.user.user_id}
      </p>
      <p>
  Report: <a href={userState.user.report_link} target="_blank">{userState.user.report_link}</a>
</p>
       
    </Card>
  );
};

export default Dash;

//{ui.theme === "flame" ? <Report /> : <DarkReport />}