import React, { useEffect, useState } from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./components/Home";
import ConfirmDelete from "./components/ConfirmDelete";

import { FlameTheme, FlameGlobalStyles } from "@lightspeed/flame/Core";

import { useSelector, useDispatch } from "react-redux";

import { setTheme } from "./redux/actions/uiActions";
import Login from "./components/login/Login";
import Recover from "./components/login/Recover";
import Register from "./components/login/Register";

// process object if not defined 
if (typeof process === "undefined") {
  window.process = {
    env: {
      NODE_ENV: "development", // "production"
    },
  };
}

const App = () => {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const userState = useSelector((state) => state.userState);

  const [userValue, setUserValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  const userData = { userValue, setUserValue, passwordValue, setPasswordValue };

  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem("theme"));
    if (storage) {
      dispatch(setTheme(storage));
    }
  }, [dispatch]);

  return (
    <FlameTheme themeName={ui.theme}>
      <FlameGlobalStyles themeName={ui.theme} />
      <ConfirmDelete />
      <BrowserRouter>
        {userState.user.user === "" ? (
          <Switch>
            <Route exact path="/recover">
              <Recover />
            </Route>
            <Route exact path="/register">
              <Register {...userData} />
            </Route>
            <Route path="/">
              <Login {...userData} />
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route path="/" component={Home} />
          </Switch>
        )}
      </BrowserRouter>
    </FlameTheme>
  );
};

export default App;
